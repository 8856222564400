require('./bootstrap');
require('./codegen');

var app = {

    settings: {},

    /**
	 * Container for all init functions.
	 */
	init: function() {
		s = this.settings;
        this.initShopwareSyncButton();
	},

	/**
	 * Container for all functions which should execute on resize event.
	 */
	initResize: function() {
		s = this.settings;
	},

    initShopwareSyncButton: function() {
        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });

        $('#shopwareSync').on('click', function(e) {
            var icon = $(this).find('svg'),
                spinner = $(this).find('.spinner');

            icon.hide();
            spinner.fadeIn();
            var data = {};

            $.ajax({
                type : 'POST',
                data : data,
                url  : "/shopware-sync",
                success: function(data){
                    spinner.hide();
                    icon.fadeIn();
                },
                error: function(jqXHR, textStatus, errorThrown) {
                    // Error Handler
                    console.log( jqXHR);
                    console.log( textStatus + errorThrown );
                    icon.fadeIn();
                    spinner.fadeOut();
                }
            });
        });
    }

}

$( function() {

    'use strict';
	app.init();

} )

/**
 * Resize Event containing the initResize function.
 * If you need this function remove the comment.
 */
/*
$( window ).resize(function() {

	'use strict';

	app.initResize();

} )
*/
