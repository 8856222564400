var codegen = {

    settings: {},

    /**
	 * Container for all init functions.
	 */
	init: function() {
        s = this.settings;
        this.eventHandler();
        this.copyCodesToClipboardButton();
    },

    eventHandler: function() {

        // Load Preset Button
        $( '#codegenerator #formWrapper').on('click', '#loadPreset', function(e)  {
            codegen.ajaxHandler($(this));
        });

        // Create New Preset Button
        $( '#codegenerator #formWrapper').on('click', '#createNewPreset', function(e)  {
            codegen.ajaxHandler($(this));
        });

        // Change Product group
        $( '#codegenerator #formWrapper').on('change', 'select[name="pg"]', function(e)  {
            codegen.ajaxHandler($(this));
        });

        // Change Placement
        $( '#codegenerator #formWrapper').on('click', '.placement-checkbox', function(e)  {
            codegen.ajaxHandler($(this));
        });

        // Change Type
        $( '#codegenerator #formWrapper').on('click', '.type-checkbox', function(e)  {
            codegen.ajaxHandler($(this));
        });

        // Save Codes
        $( '#codegenerator #formWrapper').on('click', 'input[type="submit"]', function(e)  {
            e.preventDefault();
            codegen.saveCodes();
        });

        // Submission
        $( '#codegenerator #formWrapper').on('click', '#saveAsNewPreset', function(e)  {
            e.preventDefault();
            codegen.saveCodes($(this));
        });

    },

    /**
     *
     * @param {jQuery} $element
     */
    ajaxHandler: function($element = '') {
        var data = codegen.getSelection();

        if ( $element.length > 0 && $element.attr('id') == 'loadPreset' ) {
            data['presetno']  = $('#codegenerator #formWrapper input[name="presetno"]').val();
            data['action'] = "loadPreset";
        }
        else if ( $element.length > 0 && $element.attr('id') == 'createNewPreset' ) {
            data['action'] = "createNewPreset";
        }
        else {
            // Check if the element was removed.
            var removedAttribute = codegen.isValueRemoved($element);

            if ( removedAttribute ) {
                data['removed_element'] = removedAttribute;
            }
        }

        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });
        var ajaxurl = $('#codegenerator #formWrapper form input[name="ajaxurl"]').val();

        $.ajax({
            type : 'POST',
            data : data,
            url  : ajaxurl,
            success: function(data){
                $('#codegenerator #formWrapper').html(data.html);
            },
            error: function(jqXHR, textStatus, errorThrown) {
                // Error Handler
                console.log( jqXHR);
                console.log( textStatus + errorThrown );
            }
        });

    },

    /**
     * Retrieves, if the given element was removed from Codegen form.
     * In case of product group select field it is true, when the value was changed.
     * @param {jQuery} $element The element which fired the event.
     * @returns {(string[]|boolean)} On true it returns the given category and the removed value.
     */
    isValueRemoved: function($element) {

        if ( $element.attr('type') == "checkbox" ) {
            var checked = $element.prop("checked");
            if ( !checked ) {
                // The value was removed, when the checkbox is not checked anymore.
                var $changed_category = $element.data("type");
                var $removed_value     = $element.val();
                return {
                    'category' : $changed_category,
                    'value' : $removed_value,
                };
            }
            else {
                return false;
            }
        }
        else if ( $element.is("select") ) {

            return {
                'category' : "productgroup",
                'value' : $element.val(),
            };

        }
    },

    getSelection: function() {
        $form = $("#formWrapper form");
        var data = {
            presetno: $form.find('input[name="presetno"]').val(),
            pg:       $form.find('select[name="pg"]').val(),
        }


        // Placements
        var placement_checkboxes = $(".placement-checkbox:checked").serializeArray();
        data['placements'] = placement_checkboxes;

        // Types
        var type_checkboxes = $(".type-checkbox:checked").serializeArray();
        data['types'] = type_checkboxes;

        // Variants
        var variant_inputs = $('#variants input').serializeArray();
        data['variants'] = variant_inputs;

        return data;
    },

    saveCodes: function($element = '') {
        var data = {
            presetno : $('input[name="presetno"]').val(),
            pg       : $('select[name="pg"]').val(),
            variants : $('#variants input').serializeArray(),
        }


        if ( $element.length > 0 && $element.attr('id') == 'saveAsNewPreset' ) {
            data['action'] = 'saveAsNewPreset';
        }

        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });
        var ajaxurl = $('#codegenerator #formWrapper form').attr('action');
        $.ajax({
            type : 'POST',
            data : data,
            url  : ajaxurl,
            success: function(data){
                $('#codeOutputWrapper').html(data.html);
                $('input[name="presetno"]').val(data.presetno);
                $('html, body').animate({
                    scrollTop: $('#codeOutputWrapper').offset().top
                }, 800);

                // Initalize popovers for copy to clipboard button.
                $(function () {
                    $('[data-toggle="popover"]').popover()
                });
            },
            error: function(jqXHR, textStatus, errorThrown) {
                // Error Handler
                console.log( jqXHR);
                console.log( textStatus + errorThrown );
            }
        });
    },

    /**
     * Initalize the Button to let the user copy the codes to the clipboard.
     */
    copyCodesToClipboardButton: function(){
        $( '#codegenerator').on('click', '#copyCodesToClipboard', function(e)  {
            e.preventDefault();
            var codes = $('#codelist').text();
            navigator.clipboard.writeText(codes);
        });
    },

}

$( function() {

	'use strict';

    codegen.init();
} )
